
import { computed } from "vue";
import useStore from "@/hooks/useStore";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Timeline",
  props: ["data"],
  setup() {
  const store = useStore();
  let currentHistory = computed(function () {
      return store.Candidates.currentCandidateSelected.election_history.length;
    });
    return{
      currentHistory
    }
 }
});
