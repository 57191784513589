
import { computed, defineComponent } from "vue";

import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import Sidebar from "./Sidebar.vue";
import CardInfo from "@/components/CardInfo.vue";
import useStore from "@/hooks/useStore";
import AgeChart from "./Charts/AgeChart.vue";
import EducationChart from "./Charts/EducationChart.vue";
import EthnicityChart from "./Charts/EthnicityChart.vue";
import GenderChart from "./Charts/GenderChart.vue";
import PatrimonyChart from "./Charts/PatrimonyChart.vue";
import services from "@/services";
import TooltipInfo from "../../components/TooltipInfo.vue";
import { setCharacteristicEducation, setInfoCandidateSelected } from "@/store/candidates";
import { setCurrentLocale } from "@/store/locales";
import { setCurrentRole } from "@/store/roles";

export default defineComponent({
  components: {
    Footer,
    Navbar,
    Sidebar,
    CardInfo,
    AgeChart,
    EducationChart,
    EthnicityChart,
    GenderChart,
    PatrimonyChart,
    TooltipInfo,
  },
  data() {
    return {
      loadData: [],
      showPopper: false,
    };
  },
  setup() {
    const store = useStore();
    return {
      store
    };
  },
  async mounted() {
    const keyCandidate = Number(this.$route.params.keyCandidate);
    const roleCandidate = this.$route.params.role.toString().toLowerCase();
    const localeCandidate = this.$route.params.locale.toString().toLowerCase();
    try {
      const { dataCandidate } = await services.dataCandidates.candidate(
        keyCandidate
      );

      setInfoCandidateSelected(dataCandidate);
      setCurrentLocale(localeCandidate);
      setCurrentRole(roleCandidate);
    } catch (e) {
      console.log("Erro", e);
    }
  },
});
