
import { defineComponent } from "vue";
import Popper from "vue3-popper";

export default defineComponent({
  name: "TooltipInfo",
  components: {
    Popper,
  },
});
