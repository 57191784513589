import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-card-info w-full bg-white rounded-xl border border-neutral-light" }
const _hoisted_2 = { class: "c-card-info__title flex flex-wrap text-center text-white bg-primary-base rounded-t-xl py-5 px-3 text-base font-semibold" }
const _hoisted_3 = { class: "c-card-info__content p-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "title")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "content")
    ])
  ]))
}