
import CardInfo from "@/components/CardInfo.vue";
import Timeline from "./Timeline.vue";
import IconCandidate from "@/components/IconCandidate.vue";
import useStore from '@/hooks/useStore';
import { computed } from 'vue';

export default {
  props: [
    "name",
    "role",
    "party",
    "number",
    "education",
    "ethnicity",
    "gender",
    "age",
    "timeline",
    "image",
    "status",
  ],
  components: {
    CardInfo,
    Timeline,
    IconCandidate,
  },
 setup() {
  const store = useStore();
  let currentCandidate = computed(function () {
      return store.Candidates.currentCandidateSelected;
    });
    return{
      currentCandidate
    }
 }
};
